#Banner2_0 .home-page > .banner2-text-wrapper > .kjy6u5brq1i-editor_css {
}
#Banner2_0 .home-page > .banner2-text-wrapper > .kjy6u7aj29b-editor_css {
}
#Banner2_0 .home-page > .banner2-text-wrapper > .kjy6v1c5lsd-editor_css {
  display: none;
}
#Banner2_0 .banner-anim-elem > .home-page > .kjy6v4x03ac-editor_css {
  display: flex;
}
